/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #303030;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f8c023;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.h-available {
  height: -webkit-fill-available;
}

.single-flight .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
  display: none;
}
